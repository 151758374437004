// import { graphql, PageProps } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { FormIndex } from "../components/FormIndex";
import { Heading01 } from "../components/Heading01";
import { Layout } from "../components/Layout/index";
import bgimgPc from "../images/h1/h1contact.png";
import bgimgSp from "../images/h1/h1contact_sp.png";

const ContactPage: React.VFC = () => {
  return (
    <Layout title="お問い合わせ" description="ミスギのお問い合わせページです。">
      <Heading01
        label="CONTACT"
        smallLabel="お問い合わせ"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "お問い合わせ" }]} />
      <div className="l-container-sm pt-8 pb-16 pc:pt-16 pc:pb-32">
        <FormIndex />
      </div>
    </Layout>
  );
};

export default ContactPage;
