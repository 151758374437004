import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import * as FormStyle from "./Form.module.scss";
import { FormInputs } from "../../types/FormInput";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import MyLink from "../MyLink";

/**
 * お問い合わせフォーム。入力と確認までです。
 * 完了は別ページへ。
 * バックエンドも別で書いてください。
 */
export const Presenter: React.VFC = () => {
  // フォーム送信時のエラーメッセージ表示制御
  const [formSubmitMessage, setFormSubmitMessage] = useState<boolean>(false);

  // 送信ボタン活性化制御
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] =
    useState<boolean>(false);

  // 確認画面の表示制御
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  // 確認画面の内容を入れる用のオブジェクト、初期値は空で設定
  const [confirmContents, setConfirmContents] = useState<
    FormInputs | { [key: string]: never }
  >({});

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty, isValid, errors },
  } = useForm<FormInputs>({
    mode: "onChange",
  });

  // 確認ボタン非活性状態の条件
  const isDisabled = () => !isDirty || !isValid;

  const { executeRecaptcha } = useGoogleReCaptcha();

  // バックエンドへの送信処理
  const onSubmit = async (formInputs: FormInputs): Promise<void> => {
    // 送信ボタン非活性に
    setIsSubmitBtnDisabled(true);
    // executeRecaptchaなければ処理ストップ
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      // 送信ボタン活性化
      setIsSubmitBtnDisabled(false);
      return;
    }

    // tokenの取得
    const reCaptchaToken = await executeRecaptcha("contactForm");

    // tokenなければこの先処理させない
    if (!reCaptchaToken) {
      // 送信ボタン活性化
      setIsSubmitBtnDisabled(false);
      // エラーメッセージの表示
      setFormSubmitMessage(true);
      return;
    }

    axios
      .post(
        "/api/contactForm",
        JSON.stringify({ ...formInputs, reCaptchaToken: reCaptchaToken }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        // replaceはブラウザバック不可
        location.replace("/thanks");
        reset();
      })
      .catch(() => {
        // エラーメッセージの表示
        setFormSubmitMessage(true);
      })
      .finally(() => {
        window.scrollTo({
          top: 100,
          left: 0,
          // behavior: "smooth",
        });
        // 送信ボタン活性化
        setIsSubmitBtnDisabled(false);
      });
  };

  return (
    <>
      {/* ヘッダーPRODUCTSメニューとの重なりを考慮してz-[-1]に */}
      <ul className="relative z-[-1] mx-auto flex w-[250px] justify-between pc:w-[420px]">
        <li className={`flex-shrink-0 px-6 pc:px-12`}>
          <span
            className={`relative flex h-[30px] w-[30px] items-center justify-center rounded-full border border-solid
           text-[14px] font-medium after:absolute after:top-1/2 after:right-[-24px] after:h-1.5 
           after:w-1.5 after:-translate-y-1/2 after:rotate-[45deg] after:border-t after:border-r after:border-solid
            after:border-primary after:duration-300 after:content-['']
          pc:h-10 pc:w-10 pc:text-[20px] pc:after:right-[-48px] pc:after:h-3 pc:after:w-3
           ${
             !isConfirm
               ? `border-primary bg-primary text-white`
               : `border-grey text-grey`
           }`}
          >
            1
          </span>
          <p
            className={`mt-3 text-center text-base pc:text-[20px] ${
              !isConfirm ? `font-medium text-primary` : `text-grey`
            }`}
          >
            入力
          </p>
        </li>
        <li className={`flex-shrink-0 px-6 pc:px-12`}>
          <span
            className={`relative flex h-[30px] w-[30px] items-center justify-center rounded-full border border-solid
           text-[14px] font-medium after:absolute after:top-1/2 after:right-[-24px] after:h-1.5 
           after:w-1.5 after:-translate-y-1/2 after:rotate-[45deg] after:border-t after:border-r after:border-solid
            after:border-primary after:duration-300 after:content-['']
          pc:h-10 pc:w-10 pc:text-[20px] pc:after:right-[-48px] pc:after:h-3 pc:after:w-3
           ${
             isConfirm
               ? `border-primary bg-primary text-white`
               : `border-grey text-grey`
           }`}
          >
            2
          </span>
          <p
            className={`mt-3 text-center text-base pc:text-[20px] ${
              isConfirm ? `font-medium text-primary` : `text-grey`
            }`}
          >
            確認
          </p>
        </li>
        <li className={`flex-shrink-0 px-6 pc:px-12`}>
          <span
            className={`relative flex h-[30px] w-[30px] items-center justify-center rounded-full border border-solid
           border-grey text-[14px] font-medium text-grey pc:h-10 pc:w-10 pc:text-[20px]
           `}
          >
            3
          </span>
          <p className={`mt-3 text-center text-base text-grey pc:text-[20px]`}>
            完了
          </p>
        </li>
      </ul>
      <p className="mt-10 text-center text-sm pc:text-base">
        お問い合わせ後、いただいた内容を確認の上、
        <br className="pc:hidden" />
        弊社担当者よりご連絡させていただきます。
      </p>

      {/* ---------------- */}
      {/* ------------------------ フォーム本体ここから ------------------------------ */}
      {/* ---------------- */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`my-10 ${FormStyle.formInputColor}`}
        id="form"
      >
        {/* ---------------- */}
        {/* ------------------------ エラーメッセージ ------------------------------ */}
        {/* ---------------- */}

        {/* 入力画面のエラーメッセージ */}
        {/* 初期状態では非表示のため、isValideでなく、errorsのlengthでチェックする */}
        {!isConfirm && Object.keys(errors).length !== 0 && (
          <p className="mt-6 text-center text-[16px] text-err pc:mt-8">
            入力内容に誤りがあります。ご確認ください。
          </p>
        )}

        {/* 確認画面のエラーメッセージ */}
        {isConfirm && formSubmitMessage && (
          <p className="mt-6 text-center text-[16px] text-err pc:mt-8">
            送信に失敗しました。
            <br />
            もう一度内容をご確認の上、再度送信してください。
          </p>
        )}

        {/* register your input into the hook by invoking the "register" function */}

        {/* ---------------- */}
        {/* ------------------------ お問い合わせの種類 ------------------------------ */}
        {/* ---------------- */}
        <div className="mt-10 flex flex-col border-b border-b-[#E0E0E0] py-10 pc:flex-row pc:py-14">
          <div
            id="contactTypeGroupLabel"
            className="flex flex-shrink-0 items-baseline justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            お問い合わせの種類
            <span className="ml-2 inline-block rounded-2xl border border-solid border-err bg-[#FFF0F0] px-3 py-1 text-xs text-err">
              必須
            </span>
          </div>

          <div className="mt-6 pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <ul
                className={`space-y-6 text-base ${FormStyle.radioStyle}`}
                aria-labelledby="contactTypeGroupLabel"
              >
                <li>
                  <label htmlFor="product">
                    <input
                      {...register("contactType", {
                        required: "お問い合わせの種類を選択してください。",
                      })}
                      type="radio"
                      id="product"
                      value="product"
                    />
                    <span>製品について</span>
                  </label>
                </li>
                <li>
                  <label htmlFor="supplier">
                    <input
                      {...register("contactType", {
                        required: "お問い合わせの種類を選択してください。",
                      })}
                      type="radio"
                      id="supplier"
                      value="supplier"
                    />
                    <span>仕入先・ご購入先について</span>
                  </label>
                </li>
                <li>
                  <label htmlFor="oem">
                    <input
                      {...register("contactType", {
                        required: "お問い合わせの種類を選択してください。",
                      })}
                      type="radio"
                      id="oem"
                      value="oem"
                    />
                    <span>OEM事業のご相談</span>
                  </label>
                </li>
                <li>
                  <label htmlFor="others">
                    <input
                      {...register("contactType", {
                        required: "お問い合わせの種類を選択してください。",
                      })}
                      type="radio"
                      id="others"
                      value="others"
                    />
                    <span>その他</span>
                  </label>
                </li>
              </ul>
            ) : (
              // 確認画面の内容
              <p className="text-base">
                {confirmContents.contactType === "product" && "製品について"}
                {confirmContents.contactType === "supplier" &&
                  "仕入先・ご購入先について"}
                {confirmContents.contactType === "oem" && "OEM事業のご相談"}
                {confirmContents.contactType === "others" && "その他"}
              </p>
            )}

            {/* 確認画面にはエラー文出さない */}
            {!isConfirm && errors.contactType && (
              <p className="mt-3 inline-block text-base text-err">
                {errors.contactType.message}
              </p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ お問い合わせの種類 ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ お問い合わせ内容 ------------------------------ */}
        {/* ---------------- */}
        <div className="flex flex-col border-b border-b-[#E0E0E0] py-10 pc:flex-row pc:py-14">
          <label
            htmlFor="inquiry"
            className="flex flex-shrink-0 items-baseline justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            お問い合わせ内容
            <span className="ml-2 inline-block rounded-2xl border border-solid border-err bg-[#FFF0F0] px-3 py-1 text-xs text-err">
              必須
            </span>
          </label>
          <div className="mt-6 w-full text-base pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <textarea
                {...register("inquiry", {
                  required: "お問い合わせ内容を入力してください。",
                })}
                id="inquiry"
                required
                rows={8}
                placeholder="お問い合わせ内容を入力してください。"
                className={`w-full
                     resize-none
                     rounded
                     border
                     border-white
                     bg-secondary
                     p-6
                     text-base
                     outline-none
                     focus-visible:shadow-none
                     ${
                       errors.inquiry
                         ? `border-err bg-[#FFF5F5]`
                         : `focus:border-primary`
                     }
            `}
              ></textarea>
            ) : (
              // 確認画面の内容
              <p className="text-base">{confirmContents.inquiry}</p>
            )}

            {/* 確認画面にはエラー文出さない */}
            {!isConfirm && errors.inquiry && (
              <p className="mt-3 inline-block text-base text-err">
                {errors.inquiry.message}
              </p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ お問い合わせ内容 ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ 貴社名 ------------------------------ */}
        {/* ---------------- */}
        <div className="flex flex-col border-b border-b-[#E0E0E0] py-10 pc:flex-row pc:py-14">
          <label
            htmlFor="company"
            className="flex flex-shrink-0 items-center justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            貴社名
            <span className="ml-2 inline-block rounded-2xl border border-solid border-err bg-[#FFF0F0] px-3 py-1 text-xs text-err">
              必須
            </span>
          </label>
          <div className="mt-6 w-full text-base pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <input
                {...register("company", {
                  required: "貴社名を入力してください。",
                })}
                id="company"
                type="text"
                placeholder="例）株式会社ミスギ"
                className={`w-full
                     border
                     border-white bg-secondary
                     py-5 px-6
                     text-base
                     outline-none
                     focus-visible:shadow-none
                     ${
                       errors.company
                         ? `border-err bg-[#FFF5F5]`
                         : `focus:border-primary`
                     }`}
              />
            ) : (
              // 確認画面の内容
              <p className="text-base">{confirmContents.company}</p>
            )}

            {/* 確認画面にはエラー文出さない */}
            {!isConfirm && errors.company && (
              <p className="mt-3 inline-block text-base text-err">
                {errors.company.message}
              </p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ 貴社名 ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ お名前（漢字） ------------------------------ */}
        {/* ---------------- */}
        <div className="flex flex-col border-b border-b-[#E0E0E0] py-10 pc:flex-row pc:py-14">
          <label
            htmlFor="name"
            className="flex flex-shrink-0 items-center justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            お名前（漢字）
            <span className="ml-2 inline-block rounded-2xl border border-solid border-err bg-[#FFF0F0] px-3 py-1 text-xs text-err">
              必須
            </span>
          </label>
          <div className="mt-6 w-full text-base pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <input
                {...register("name", {
                  required: "お名前（漢字）を入力してください。",
                })}
                id="name"
                type="text"
                placeholder="例）三杉 太郎"
                className={`w-full
                     border
                     border-white bg-secondary
                     py-5 px-6
                     text-base
                     outline-none
                     focus-visible:shadow-none
                     ${
                       errors.name
                         ? `border-err bg-[#FFF5F5]`
                         : `focus:border-primary`
                     }`}
              />
            ) : (
              // 確認画面の内容
              <p className="text-base">{confirmContents.name}</p>
            )}

            {/* 確認画面にはエラー文出さない */}
            {!isConfirm && errors.name && (
              <p className="mt-3 inline-block text-base text-err">
                {errors.name.message}
              </p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ お名前（漢字） ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ お名前（ふりがな） ------------------------------ */}
        {/* ---------------- */}
        <div className="flex flex-col border-b border-b-[#E0E0E0] py-10 pc:flex-row pc:py-14">
          <label
            htmlFor="nameKana"
            className="flex flex-shrink-0 items-center justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            お名前（ふりがな）
            <span className="ml-2 inline-block rounded-2xl border border-solid border-err bg-[#FFF0F0] px-3 py-1 text-xs text-err">
              必須
            </span>
          </label>
          <div className="mt-6 w-full text-base pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <input
                {...register("nameKana", {
                  required: "お名前（ふりがな）を入力してください。",
                })}
                id="nameKana"
                type="text"
                placeholder="例）みすぎ たろう"
                className={`w-full
                     border
                     border-white bg-secondary
                     py-5 px-6
                     text-base
                     outline-none
                     focus-visible:shadow-none
                     ${
                       errors.nameKana
                         ? `border-err bg-[#FFF5F5]`
                         : `focus:border-primary`
                     }`}
              />
            ) : (
              // 確認画面の内容
              <p className="text-base">{confirmContents.nameKana}</p>
            )}

            {/* 確認画面にはエラー文出さない */}
            {!isConfirm && errors.nameKana && (
              <p className="mt-3 inline-block text-base text-err">
                {errors.nameKana.message}
              </p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ お名前（ふりがな） ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ メールアドレス ------------------------------ */}
        {/* ---------------- */}
        <div className="flex flex-col border-b border-b-[#E0E0E0] py-10 pc:flex-row pc:py-14">
          <label
            htmlFor="email"
            className="flex flex-shrink-0 items-center justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            メールアドレス
            <span className="ml-2 inline-block rounded-2xl border border-solid border-err bg-[#FFF0F0] px-3 py-1 text-xs text-err">
              必須
            </span>
          </label>
          <div className="mt-6 w-full text-base pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <input
                {...register("email", {
                  required: "メールアドレスを入力してください。",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                    message: "メールアドレスの形式が正しくありません。",
                  },
                })}
                id="email"
                type="text"
                placeholder="例）mail@misugi.com"
                className={`w-full
                     border
                     border-white bg-secondary
                     py-5 px-6
                     text-base
                     outline-none
                     focus-visible:shadow-none
                     ${
                       errors.email
                         ? `border-err bg-[#FFF5F5]`
                         : `focus:border-primary`
                     }`}
              />
            ) : (
              // 確認画面の内容
              <p className="text-base">{confirmContents.email}</p>
            )}

            {/* 確認画面にはエラー文出さない */}
            {!isConfirm && errors.email && (
              <p className="mt-3 inline-block text-base text-err">
                {errors.email.message}
              </p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ メールアドレス ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ 電話番号 ------------------------------ */}
        {/* ---------------- */}
        <div className="flex flex-col border-b border-b-[#E0E0E0] py-10 pc:flex-row pc:py-14">
          <label
            htmlFor="phone"
            className="flex flex-shrink-0 items-center justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            電話番号
            <span className="ml-2 inline-block rounded-2xl border border-solid border-err bg-[#FFF0F0] px-3 py-1 text-xs text-err">
              必須
            </span>
          </label>
          <div className="mt-6 w-full text-base pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <input
                {...register("phone", {
                  required: "電話番号を入力してください。",
                  pattern: {
                    value: /^(0{1}\d{9,10})$/,
                    message: "電話番号の形式が正しくありません。",
                  },
                })}
                id="phone"
                type="tel"
                placeholder="例）0444311134  ハイフンなしの半角数字"
                className={`w-full
                     border
                     border-white bg-secondary
                     py-5 px-6
                     text-base
                     outline-none
                     focus-visible:shadow-none
                     ${
                       errors.phone
                         ? `border-err bg-[#FFF5F5]`
                         : `focus:border-primary`
                     }`}
              />
            ) : (
              // 確認画面の内容
              <p className="text-base">{confirmContents.phone}</p>
            )}

            {/* 確認画面にはエラー文出さない */}
            {!isConfirm && errors.phone && (
              <p className="mt-3 inline-block text-base text-err">
                {errors.phone.message}
              </p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ 電話番号 ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ 郵便番号 ------------------------------ */}
        {/* ---------------- */}
        <div className="flex flex-col border-b border-b-[#E0E0E0] py-10 pc:flex-row pc:py-14">
          <label
            htmlFor="zipCode"
            className="flex flex-shrink-0 items-center justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            郵便番号
          </label>
          <div className="mt-6 w-full text-base pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <input
                {...register("zipCode", {
                  pattern: {
                    value: /^[0-9]{3}-[0-9]{4}$/,
                    message: "郵便番号の形式が正しくありません。",
                  },
                })}
                id="zipCode"
                type="text"
                inputMode="numeric"
                placeholder="例）000-0000"
                className={`w-full
                     border
                     border-white bg-secondary
                     py-5 px-6
                     text-base
                     outline-none
                     focus-visible:shadow-none
                     ${
                       errors.zipCode
                         ? `border-err bg-[#FFF5F5]`
                         : `focus:border-primary`
                     }`}
              />
            ) : (
              // 確認画面の内容
              <p className="text-base">{confirmContents.zipCode}</p>
            )}

            {/* 確認画面にはエラー文出さない */}
            {!isConfirm && errors.zipCode && (
              <p className="mt-3 inline-block text-base text-err">
                {errors.zipCode.message}
              </p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ 郵便番号 ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ 住所 ------------------------------ */}
        {/* ---------------- */}
        <div className="flex flex-col py-10 pc:flex-row pc:py-14">
          <label
            htmlFor="address"
            className="flex flex-shrink-0 items-center justify-between text-base font-medium text-dark pc:min-w-[220px]"
          >
            住所
          </label>
          <div className="mt-6 w-full text-base pc:mt-0 pc:ml-[72px]">
            {!isConfirm ? (
              // 入力画面
              <input
                {...register("address")}
                id="address"
                type="text"
                placeholder="例）神奈川県川崎市中原区丸子通"
                className="
                    w-full
                     border
                     border-white bg-secondary
                     py-5 px-6
                     text-base
                     outline-none
                     focus:border-primary
                     focus-visible:shadow-none
                     "
              />
            ) : (
              // 確認画面の内容
              <p className="text-base">{confirmContents.address}</p>
            )}
          </div>
        </div>
        {/* ---------------- */}
        {/* ------------------------ 住所 ------------------------------ */}
        {/* ---------------- */}

        {/* ---------------- */}
        {/* ------------------------ ボタン ------------------------------ */}
        {/* ---------------- */}
        <div className="flex justify-center">
          {!isConfirm ? (
            // 確認画面へ遷移するボタン
            <button
              disabled={isDisabled()}
              onClick={() => {
                setIsConfirm(true);
                setConfirmContents(getValues());
                window.scrollTo({
                  top: 100,
                  left: 0,
                  // behavior: "smooth",
                });
              }}
              className={`relative mt-4 w-[180px] rounded-full 
                     border p-3 text-base text-white
                     transition before:absolute before:right-4 
                     before:top-[calc(50%-0.25rem)] before:h-2 before:w-2 before:rotate-45 
                     before:border-t before:border-r before:content-['']
                     ${
                       !isDisabled()
                         ? `cursor-pointer bg-primary hover:bg-opacity-70` //活性時
                         : `bg-[#D5D5D5]` //非活性時
                     }
                     `}
            >
              確認画面へ
            </button>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-6">
              {/* 送信ボタン */}
              <button
                type="submit"
                className={`relative mt-10 w-[180px] rounded-full border 
                     p-3 text-base text-white transition
                     before:absolute before:right-4 before:top-[calc(50%-0.25rem)] 
                     before:h-2 before:w-2 before:rotate-45 before:border-t 
                     before:border-r before:content-[''] hover:bg-opacity-70
                     ${isSubmitBtnDisabled ? `bg-[#D5D5D5]` : `bg-primary`}`}
              >
                {isSubmitBtnDisabled ? "送信中..." : "送信する"}
              </button>
              {/* 入力画面へ戻るリンク */}
              <button
                onClick={() => {
                  setIsConfirm(false);
                  window.scrollTo({
                    top: 100,
                    left: 0,
                  });
                }}
                className="relative inline-block pl-5 text-primary
    duration-300 before:absolute before:top-1/2 before:left-0 before:h-4 before:w-4
    before:-translate-y-1/2 before:rounded-full before:border before:border-solid
    before:border-primary before:duration-300 before:content-['']
    after:absolute after:top-1/2 after:left-[6px] after:h-1.5 
    after:w-1.5 after:-translate-y-1/2 after:rotate-[225deg] after:border-t after:border-r
    after:border-solid after:border-primary after:duration-300 after:content-['']
    hover:underline hover:before:bg-primary hover:after:border-white"
              >
                入力画面へ戻る
              </button>
            </div>
          )}
        </div>
        <p className="mt-10 text-xs">
          This site is protected by reCAPTCHA and the Google
          <MyLink
            myClassName="text-primary"
            to="https://policies.google.com/privacy"
          >
            Privacy Policy
          </MyLink>
          and
          <MyLink
            myClassName="text-primary"
            to="https://policies.google.com/terms"
          >
            Terms of Service
          </MyLink>
          apply.
        </p>
      </form>
    </>
  );
};
